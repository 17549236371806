import api from '@/api/index'

export default {
  get: async payload => {
    const response = await api.customer.get(payload)
    const { data } = response
    return data
  },
  searchCustomer: async payload => {
    const response = await api.customer.get(payload)
    const { data } = response
    return data
  },
  getById: async payload => {
    const response = await api.customer.getById(payload)
    const { data } = response
    return data
  },
  save: async payload => {
    const response = await api.customer.save(payload)
    const { data } = response
    return data
  },
  deleteImage: async payload => {
    await api.customer.deleteImage(payload)
  },
}
