<template>
  <b-card-code>
    <b-card-body class="rows-responsive">
      <basic-table
        :fields="fields"
        :data="data"
        :button-table="`customer.new_customer`"
        :settings="settings"
        :is-busy="isBusy"
        :on-address="filterAddress"
        @onCreate="(data) => create(data)"
        @changePage="onPagination"
        @onPerPage="onPerPage"
        @changeAddressApi="getLocation"
        @onButtonClick="goToForm"
        @onItemClick="goToForm"
        @search="searchCustomer"
      />
    </b-card-body>
  </b-card-code>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCardBody } from 'bootstrap-vue'
import BCardCode from '@/views/drs/components/BCardCode.vue'
import BasicTable from '@/views/drs/components/BasicTable.vue'
import dbCustomers from '@/models/customer'
import geoLocation from '@/models/geoLocation'
import generateToken from '@/auth/geoAuth'

export default {
  components: {
    BCardCode,
    BCardBody,
    BasicTable,
  },
  data: () => ({
    filterAddress: {},
    fields: [
      { key: 'name', label: 'customer.name', sortable: true },
      { key: 'email', label: 'customer.email', sortable: true },
      { key: 'phone', label: 'customer.phone', sortable: true },
      { key: 'city', label: 'customer.city', sortable: true },
    ],
    settings: {
      total: 1,
    },
    data: [],
    searchItem: '',
    perPage: 50,
    isBusy: false,
  }),
  async created() {
    this.fetch(1)
  },
  methods: {
    searchCustomer(item) {
      this.searchItem = item
      if (this.searchItem.length > 2 || this.searchItem.length === 0) this.fetch(1)
    },
    async fetch(page) {
      this.isBusy = true
      const response = await dbCustomers.get({
        page, perPage: this.perPage, paginate: true, word: this.searchItem,
      })
      this.isBusy = false
      this.data = response.data
      delete response.data
      this.settings = response
    },
    async getLocation(info) {
      const bearer = await generateToken()
      const payload = {
        cp: info.cp,
        house_number: info.house_number,
        bearer,
      }
      this.filterAddress = await geoLocation.get(payload)
    },
    async create(data) {
      try {
        await dbCustomers.save(data)
        await this.fetch()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.$i18n.t('toast.customer.create')}`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.$i18n.t('toast.customer.error')}`,
            icon: 'XIcon',
            variant: 'error',
          },
        })
      }
    },
    onPagination(page) {
      this.fetch(page)
    },
    onPerPage(size) {
      this.perPage = size
      this.fetch(1)
    },
    goToForm(item) {
      this.$router.push(`/customers/customers/${item ? item.id : 'new'}`)
    },
  },
}
</script>
<style scoped>
</style>
